var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('div', {
    staticClass: "d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30",
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  }, [_c('h1', {
    staticClass: "font-size-sm-100 font-weight-boldest text-dark-75",
    staticStyle: {
      "font-size": "150px"
    }
  }, [_vm._v(" 404 ")]), _c('p', {
    staticClass: "font-size-h3 font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.$t("OOPS! Something went wrong here")) + " ")]), _vm.isAuthenticated ? _c('div', {
    staticClass: "mt-3"
  }, [_c('router-link', {
    staticClass: "btn btn-primary font-weight-bold",
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Back to dashboard")) + " ")])], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }