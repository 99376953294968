<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30" :style="{
      backgroundImage: `url(${backgroundImage})`
    }">
      <!-- begin:: Content -->
      <h1 class="font-size-sm-100 font-weight-boldest text-dark-75" style="font-size: 150px;">
        404
      </h1>
      <p class="font-size-h3 font-weight-light">
        {{ $t("OOPS! Something went wrong here") }}
      </p>
      <div v-if="isAuthenticated" class="mt-3">
        <router-link class="btn btn-primary font-weight-bold" :to="{ name: 'dashboard' }">
          {{ $t("Back to dashboard") }}
        </router-link>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Error1",
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg1.jpg";
    }
  },
};
</script>
